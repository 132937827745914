<template>
  <div class="home">
    <section class="head">
      <div class="img"><img src="../assets/1.png" /></div>
      <div class="name">
        <p class="zh">要事助手</p>
        <p class="en">{{ "important reminder".toUpperCase() }}</p>
      </div>
    </section>
    <div class="desc">
      <div class="down">
        <label>要事助手APP</label>
        <span><a href="https://flame-public-read.oss-cn-beijing.aliyuncs.com/notice/notice.apk">立即下载</a></span>
      </div>
      <div class="info">
        <label>介绍</label>
        <div>
          要事助手APP，主要针对日常事多，记忆不好的用户，经常忘记一些重要的节日与事情，而造成的烦恼；通过邮件、短信、语音电话通知方式，24小时监听提醒，再也不用担心遗忘
        </div>
        <div class="imgs">
          <img src="../assets/1.jpg">
          <img src="../assets/2.jpg">
          <img src="../assets/3.jpg">
          <img src="../assets/4.jpg">
        </div>
      </div>
    </div>
    <div class="list"></div>
  </div>
</template>

<script>
export default {
  name: "Home",
  mounted() {},
};
</script>
<style scoped lang="scss">
.home {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .head {
    height: 300px;
    width: 100%;
    background: #ff4343;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 20%;
    box-sizing: border-box;
    .name {
      color: #fff;
      white-space: nowrap;
      .zh {
        font-size: 30px;
        letter-spacing: 35px;
        text-indent: 30px;
      }
      .en {
        letter-spacing: 2px;
        padding-top: 15px;
      }
    }
    .img {
      img {
        height: 250px;
      }
    }
  }
  .desc {
    color: #fff;
    width: 100%;
    background: #4d2323;
    display: flex;
    padding: 25px 20%;
    box-sizing: border-box;
    flex-direction: column;
    text-align: left;
    .info{
      margin-top: 20px;
      div{
        margin-top: 10px;
        margin-bottom: 15px;
      }
      .imgs{
        img{
          width: 30%;
          margin-right: 10%;
          margin-bottom: 15px;
        }
      }
    }
    .down{
      a{
        text-decoration: none;
        color: #fff;
      }
      span{
        margin-left: 20px;
        background: #e69200;
        padding: 5px 10px;
        border-radius: 4px;
      }
    }
  }
}
</style>
